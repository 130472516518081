<template>
    <div>
        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>
                        <strong>Update Token</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CForm @keyup.enter="validator();submit()">
                            <CRow>
                                <CCol sm="3">
                                    <label>User</label>
                                        <model-select :options="users" v-model="form.user_id.val" placeholder="Please Select">
                                        <small class="form-text text-muted w-100">{{form.user_id.inv}}</small>
                                    </model-select>
                                </CCol>
                                <CCol sm="3">
                                    <label>From Date</label>
                                    <date-picker placeholder="Please Select" locale="en" v-model="form.token_validation_from.val"></date-picker>
                                    <small class="form-text text-muted w-100">{{form.token_validation_from.inv}}</small>
                                </CCol>
                                <CCol sm="3">
                                    <label>To Date</label>
                                    <date-picker placeholder="Please Select" locale="en" v-model="form.token_validate_until.val"></date-picker>
                                    <small class="form-text text-muted w-100">{{form.token_validate_until.inv}}</small>
                                </CCol>
                            </CRow>
                            <br>
                        </CForm>
                        <br>
                        <div class="form-group form-actions">
                            <CButton @click="validator();submit()" color="primary" class="mr-2"> Update</CButton>
                            <CButton color="dark" @click="go_back">Back</CButton>
                        </div>    
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import { ModelSelect } from 'vue-search-select'
export default {
    components: { ModelSelect },
    data() {
        return {
            form: {
                user_id: {val: '', inv: '', v: { req: true, max: 1024 }},
                token_validation_from: {val: '', inv: '', v: { req: true, max: 1024 }},
                token_validate_until: {val: '', inv: '', v: { req: true, max: 1024 }},
            },
            users: [],
            disable: false,
        }
    },
    mounted(){
        this.get_users();
        this.get();
    },
    methods: {
        validator() {
            for (const key in this.form) {
                this.form[key].inv = '';
            }
            this.disable = false;
            for (const key in this.form) {
                for (const k in this.form[key].v) {
                    switch (k) {
                        case 'req':
                            if (this.form[key].val == '') {
                                this.form[key].inv = 'This field is required.';
                                this.disable = true;
                            }
                            break;
                        case 'max':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length > this.form[key].v[k]) {
                                    this.form[key].inv = `Must be a maximum of ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                        case 'min':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length < this.form[key].v[k]) {
                                    this.form[key].inv = `Must be at least ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                    }
                }
            }
        },
        get_users(){
            this.axios.get('system/users/'
                + '?is_guest=true')
                .then(res => {
                    res.data.map(val => {
                        this.users.push({ value: val.id, text: `${val.id} - ${val.first_name} ${val.last_name}` });
                    });
                })
        },
        get(){
           this.axios.get('system/guest_user_tokens/'
                + '?id=' + this.$route.params.id)
                .then(res => {
                    for(const key in res.data[0]){
                        for(const k in this.form){
                            if(key == k){
                                this.form[k].val = res.data[0][key];
                            }
                        }
                    }
                }) 
        },
        submit(){
            if(this.disable == false){
                const data = new FormData();
                data.set('ids', JSON.stringify([parseInt(this.$route.params.id)]));
                for (const key in this.form){
                    this.form[k].val = res.data[0][key];
                }
                this.axios.put('system/guest_user_tokens/', data)
                    .then(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            text: 'Updated Successfully.'
                        });
                        this.$router.push('/tokens');
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            text: 'Error! please try again.'
                        });
                    })
            }
        },
        go_back(){
            this.$router.go(-1);
        }
    }
}
</script>
